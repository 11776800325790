








import { Component, Vue } from "vue-property-decorator";
import RevisarReservaChapuzon from "@/components/Formularios/Chapuzon2025/RevisarChapuzon2025.vue";

@Component({
  components: {
    RevisarReservaChapuzon
  }
})
export default class RevisarReservaChapuzonView extends Vue {}
